<template>
  <div class="start-box">
    <div class="start-center">
      <div class="select-container">
        <div class="select-box" v-show="status != 3 && status != 4">
          <select class="select_list" @change="handleCountryChange" v-model="curCountryName">
            <option v-for="(item, index) in countryList" :key="'country' + index" :value="item">
              {{ item }}
            </option>
          </select>
          <div class="down_arrow"></div>
        </div>
        <div class="select-box" v-show="status != 3 && status != 4">
          <select class="select_list" v-model="curDocumentInfo" @change="handleDocumentChange">
            <option v-for="(item, index) in allSpecs[curCountryName]" :key="'document' + index" :value="item">
              {{ item.document_type }}
            </option>
          </select>
          <div class="down_arrow"></div>
        </div>
      </div>
      <!-- upload -->
      <div class="upload-box" v-show="status == 1 || status == 2">
        <!-- 上传页 -->
        <div class="first-part" v-show="status == 1">
          <div class="upload-total">
            <img src="../../../assets/images/add-icon.png" class="add-icon" alt="" />
            <div class="add-text">{{ $t("message.start.uploadText") }}</div>
          </div>
          <input type="file" accept="image/*" class="input-file" @click="handleFileClick" @change="handleFileChange" />
        </div>
        <!-- 上传中 -->
        <div class="second-part" v-show="status == 2">
          <div class="process">
            <div class="process-bar" :style="{ width: processBar + '%' }"></div>
          </div>
          <div>{{ processBar }}%</div>
        </div>
      </div>
      <!-- 上传完成 -->
      <div v-show="status == 3 || status == 4">
        <div class="three-part">
          <div class="three-part-box">
            <img :src="uploadUrl" class="upload-img" alt="" />
          </div>
          <div class="three-part-right">
            <div class="part-right-list">
              <div class="part-right-list-title">
                {{ $t("message.index.PassportType") }}
              </div>
              <div class="part-right-list-info">
                <div>{{ curDocumentInfo.document_type }}</div>
                <div>{{ curDocumentInfo.dimension }}</div>
              </div>
            </div>
            <div class="part-right-list">
              <div class="part-right-list-title">
                {{ $t("message.index.BackgroundColor") }}
              </div>
              <div class="part-right-list-info">
                <div>{{ curDocumentInfo.background }}</div>
                <div class="block" :style="{ background: curDocumentInfo.background_color_code }"></div>
              </div>
            </div>
            <div class="part-right-list-price">
              {{ $t("message.index.downloadText") }} <span class="old-price">$3.99</span
              ><span class="real-price">$1.99</span>
              <div class="off-price">({{ $t("message.index.priceOff") }})</div>
            </div>
            <div class="paypal-box" v-show="status == 3">
              <div id="paypal-button-container"></div>
            </div>
            <div class="download-photo" v-show="status == 4">
              <div class="download-photo-box" @click="handleDownloadClick(true)">
                <van-loading size="16" v-if="isFinshPhoto" class="load-icon" color="#2a2a2a" />
                {{ $t("message.start.download") }}
              </div>
              <div class="load-text" v-show="status == 4">{{ $t("message.start.loadText") }}</div>
            </div>
          </div>
        </div>
        <div class="again-container">
          <div class="again-btn" @click="handleAgainClick">{{ $t("message.start.againText") }}</div>
        </div>
      </div>

      <!-- tips -->
      <div class="upload-tips-box" v-show="status != 3 && status != 4">
        <div>{{ $t("message.start.uploadTips1") }} {{ $t("message.start.uploadTips2") }}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { getCountries } from "@/api/common.js";
import { uploadPhoto, parsePhoto, getFinalPhoto } from "@/api/start.js";
import { reportWebVitals } from "@/utils/ga.js";
import Notify from "vant/lib/notify";
import "vant/lib/notify/style";
import "vant/lib/loading/style";
import { mapState } from "vuex";
import Footer from "@/components/Footer.vue";
export default {
  name: "Start",
  data() {
    return {
      countryList: [],
      curCountryName: "",
      documentList: {},
      curDocumentInfo: {},
      allSpecs: {},

      id: null,
      locale: "",
      messages: null,
      photoKey: "",
      uploadUrl:
        "https://gd-filems.dancf.com/gaoding/matting/watermark/0364c2742512a76b33aff319ca9721dd1637552600913.jpg",
      status: 1, //1 上传页面 2上传中 3 上传完成 4 支付成功
      processBar: 0,
      timer: null,
      orderID: "",
      isFinshPhoto: false,
      imageDataURL: "",
    };
  },
  components: {
    Footer,
  },
  computed: mapState([
    // 映射 this.count 为 store.state.count
    "documentInfo",
  ]),
  mounted() {
    this.locale = this.$i18n.locale;
    this.messages = this.$i18n.messages;
    this.id = this.$route.query.id;
    this.getCountries();
    this.$nextTick(() => {
      this.initPayPalButton();
    });
  },
  methods: {
    handleCountryChange() {
      if (!this.allSpecs[this.curCountryName]) {
        return;
      }
      this.curDocumentInfo = this.allSpecs[this.curCountryName][0];
      // 切换国家打点
      reportWebVitals("country_select_start", 1, "spec", "", {
        countryName: this.curCountryName,
      });
    },
    handleDocumentChange() {
      // 切换类型打点
      reportWebVitals("photo_type_select_start", 1, "spec", "", {
        photoId: this.curDocumentInfo.id,
      });
    },
    // 重新上传
    handleAgainClick() {
      this.resetUpload();
      this.uploadUrl = "";
      this.orderID = "";
      reportWebVitals("reUpload_success_start", 1, "parse", "");
    },
    // 点击上传
    handleFileClick() {
      reportWebVitals("upload_btn_click_start", 1, "parse", "core");
    },
    // 选择文件
    async handleFileChange(e) {
      var file = e.target?.files[0];
      if (!file) {
        const photoInvalidMsg = this.messages[this.locale].message.start.photoInvalidMsg;
        Notify({
          type: "danger",
          message: photoInvalidMsg,
        });
        return;
      }

      let info = null;
      try {
        info = await this.getFilePhotoInfo(file);
      } catch (error) {
        console.error(error);
      }

      if (!info) {
        reportWebVitals("get_filephoto_info_error");
        const photoInvalidMsg = this.messages[this.locale].message.start.photoInvalidMsg;
        Notify({
          type: "danger",
          message: photoInvalidMsg,
        });
        return;
      }
      if (info.width > 3000 || info.height > 3000) {
        const photoInvalidMsg = this.messages[this.locale].message.start.photoInvalidMsg2;
        Notify({
          type: "danger",
          message: photoInvalidMsg,
        });
        reportWebVitals("file_photo_size_error", "", "parse","", {
          width: info.width,
          height: info.height,
        });
        return;
      }

      reportWebVitals("upload_loading_start", "", "parse");
      this.processBar = 0;
      this.status = 2;
      this.setProcessAni();
      this.uploadPhoto(file);

      e.target.value = "";
    },
    // 获取上传文件信息
    getFilePhotoInfo(file) {
      return new Promise((reslove, reject) => {
        var reader = new FileReader();
        reader.onload = function (e) {
          var data = e.target.result;
          //加载图片获取图片真实宽度和高度
          var image = new Image();
          image.onload = function () {
            var width = image.width;
            var height = image.height;
            reslove({ width, height });
          };
          image.src = data;
        };
        reader.onerror = function (e) {
          reject(e);
        };
        reader.readAsDataURL(file);
      });
    },
    // 上传进度
    setProcessAni() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.processBar === 94) {
          clearInterval(this.timer);
          return;
        }
        this.processBar = this.processBar + 2;
      }, 100);
    },
    resetUpload() {
      this.status = 1;
      this.processBar = 0;
      clearInterval(this.timer);
    },
    // 初始化支付按钮
    initPayPalButton() {
      var that = this;
      window.paypal
        .Buttons({
          style: {
            shape: "pill",
            color: "gold",
            layout: "vertical",
            label: "paypal",
          },

          createOrder: function (data, actions) {
            reportWebVitals("pay_btn_click_start", "", "pay", "core", {
              countryName: that.curCountryName,
              currency: "USD",
              price: "1.99",
            });
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: "1.99",
                  },
                },
              ],
            });
          },

          onApprove: function (data, actions) {
            return actions.order.capture().then(function (orderData) {
              if (orderData.id == null) {
                console.warn("Capture error");
                return;
              }
              that.orderID = orderData.id;
              that.onPaymentSuccess();
            });
          },
          onCancel: function () {
            /*
             * Buyer cancelled the payment
             */
            reportWebVitals("pay_cancel_start", "", "pay", "");
          },

          onError: function (err) {
            console.log(err);
            reportWebVitals("pay_fail_start", "", "pay", "", {
              error: JSON.stringify(err),
            });
          },
        })
        .render("#paypal-button-container");
    },
    // 支付成功
    onPaymentSuccess() {
      reportWebVitals("pay_success_start", "", "pay", "core");
      this.status = 4;
      this.getFinalPhoto();
    },
    // 下载图片
    handleDownloadClick(flag) {
      this.isFinshPhoto = true;

      this.downLoad("photo", this.uploadUrl, flag);
      if (flag) {
        // 点击下载按钮
        reportWebVitals("download_btn_click", "", "download", "");
      }
    },
    downLoad(downloadName, url, status) {
      reportWebVitals("download_start", 1, "download", "", {
        download_type: status ? "click" : "auto",
      });

      const tag = document.createElement("a");
      // 此属性的值就是下载时图片的名称，注意，名称中不能有半角点，否则下载时后缀名会错误
      tag.setAttribute("download", downloadName.replace(/\./g, "。"));
      const image = new Image();
      // 设置 image 的 url, 添加时间戳，防止浏览器缓存图片
      image.src = url + "?time=" + new Date().getTime();

      //重要，设置 crossOrigin 属性，否则图片跨域会报错
      image.setAttribute("crossOrigin", "Anonymous");
      // 图片未加载完成时操作会报错
      image.onload = () => {
        reportWebVitals("download_onload", 1, "download", "", {
          download_type: status ? "click" : "auto",
        });

        let url = "";
        if (this.imageDataURL) {
          url = this.imageDataURL;
        } else {
          url = this.getImageDataURL(image);
        }
        tag.href = url;
        tag.click();
        this.isFinshPhoto = false;
        this.imageDataURL = url;
        reportWebVitals("download_success_start", 1, "download", "", {
          download_type: status ? "click" : "auto",
        });
      };
    },

    getImageDataURL(image) {
      // 创建画布
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext("2d");
      // 以图片为背景剪裁画布
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // 获取图片后缀名
      const extension = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
      // 某些图片 url 可能没有后缀名，默认是 png
      return canvas.toDataURL("image/" + extension, 1);
    },
    getCountries() {
      getCountries()
        .then((res) => {
          if (res?.body?.code == 0) {
            const data = res.body.data;
            this.allSpecs = data?.specs?.specs || {};
            this.countryList = data?.specs?.countries || [];

            // url 中自带国家id
            if (this.id) {
              // 根据id 获取国家和类型信息
              for (let k in this.allSpecs) {
                const item = this.allSpecs[k];
                for (let i = 0; i < item.length; i++) {
                  if (item[i].id == this.id) {
                    this.curCountryName = k;
                    this.curDocumentInfo = this.allSpecs[this.curCountryName][i];
                    break;
                  }
                }
              }
            } else if (this.documentInfo) {
              this.curCountryName = this.documentInfo.country;
              this.curDocumentInfo = this.documentInfo;
            } else {
              this.curCountryName = (this.countryList && this.countryList[0]) || "";
              this.documentList = this.allSpecs[this.curCountryName];
              this.curDocumentInfo = (this.documentList && this.documentList[0]) || "";
            }
            reportWebVitals("spec_success", 1, "spec", "", {
              code: res.body.code,
            });
          } else {
            reportWebVitals("spec_fail", 1, "spec", "", {
              code: res.body.code,
            });
          }
        })
        .catch((err) => {
          reportWebVitals("spec_fail", 1, "spec", "", { code: "-1" });
          console.error(err);
        });
    },
    // 获取 photokey
    uploadPhoto(file) {
      uploadPhoto(file)
        .then((res) => {
          this.photoKey = res.photoKey;
          // 上传图片成功打点 获取到photoKey
          reportWebVitals("upload_success_start", "", "parse", "core");

          this.parsePhoto(res.signedUrl, res.photoKey);
        })
        .catch((err) => {
          this.resetUpload();
          // 上传图片失败打点
          reportWebVitals("upload_fail_start", "", "parse", "", {
            error: JSON.stringify(err),
          });
          const uploadErr = this.messages[this.locale].message.start.uploadErr;
          Notify({
            type: "danger",
            message: uploadErr,
          });
          console.error(err);
        });
    },
    // 水印图
    parsePhoto(signedUrl, photoKey) {
      const startTime = new Date().getTime();
      parsePhoto(signedUrl, photoKey, this.curDocumentInfo)
        .then((res) => {
          if (res?.body?.code == 0) {
            this.uploadUrl = res.body.data.url;
            this.processBar = 100;
            // 上传完成
            this.status = 3;

            // 带水印图片成功打点
            reportWebVitals("parse_photo_success", "", "parse", "");
          } else {
            const photoInvalidMsg1 = this.messages[this.locale].message.start.photoInvalidMsg1;
            Notify({
              type: "danger",
              message: photoInvalidMsg1,
            });
            this.resetUpload();
            // 带水印图片失败打点
            reportWebVitals("parse_photo_fail", "", "parse", "", {
              code: res?.body?.code || "-1",
            });
          }
          // 生成带水印图片时间打点
          const endTime = new Date().getTime() - startTime;
          reportWebVitals("handle_watermark_photo_time", endTime, "", 'time');
        })
        .catch((err) => {
          this.resetUpload();
          // 带水印图片失败打点
          reportWebVitals("parse_photo_fail", "", "parse", "", {
            error: JSON.stringify(err),
          });
          const uploadErr = this.messages[this.locale].message.start.uploadErr;
          Notify({
            type: "danger",
            message: uploadErr,
          });
          console.error(err);
        });
    },
    // 无水印图
    getFinalPhoto() {
      if (!this.orderID) {
        reportWebVitals("no_order_id");
        return;
      }
      const startTime = new Date().getTime();
      getFinalPhoto(this.orderID, this.photoKey)
        .then((res) => {
          if (res?.body?.code == 0) {
            this.uploadUrl = res.body.data.url;
            const successMsg = this.messages[this.locale].message.start.successMsg;
            Notify({
              type: "success",
              message: successMsg,
            });
            // 无水印成功打点
            reportWebVitals("get_photo_success_start", "", "download", "");
            this.handleDownloadClick();
          } else {
            const errorMsg = this.messages[this.locale].message.start.errorMsg;
            Notify({
              type: "danger",
              message: errorMsg,
            });
            this.status = 3;
            // 无水印失败打点
            reportWebVitals("get_photo_error_start", "", "download", "", {
              code: res?.body?.code || "-1",
            });
          }
          // 转成无水印时间打点
          const endTime = new Date().getTime() - startTime;
          reportWebVitals("handle_no_watermark_photo_time", endTime, "", 'time', {
            code: res?.body?.code || "-1",
          });
        })
        .catch((err) => {
          this.status = 3;
          // 无水印失败打点
          reportWebVitals("get_photo_error_start", "", "download", "", {
            code: "-1",
            error: JSON.stringify(err),
          });
          const uploadErr = this.messages[this.locale].message.start.uploadErr;
          Notify({
            type: "danger",
            message: uploadErr,
          });
          console.error(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.start-box {
  padding-top: 50px;
  .start-center {
    min-height: calc(100vh - 240px);
    padding-bottom: 60px;
  }
  .select-box {
    .select_list {
      width: 700px;
      height: 80px;
      margin: 0 auto 30px;
    }
    .down_arrow {
      right: 10%;
      width: 12px;
      height: 12px;
      right: 2%;

    }

    .down_arrow::after {
      height: 8px;
      width: 8px;
    }
  }

  .select-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .select-box {
      width: 300px;
      margin: 0 10px;

      .select_list {
        width: 100%;
        height: 36px;
        background: #fff;
        font-size: 14px;
        padding-left: 15px;
        margin: 0;
      }
    }
  }

  .upload-box {
    width: 620px;
    height: 365px;
    margin: 30px auto 0;
    background: rgba(255, 214, 0, 0.06);
    border: 0.5px solid rgba(255, 210, 0, 0.3);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .first-part {
      .upload-total {
        .add-icon {
          width: 54px;
          height: 54px;
          margin: 0 auto 20px;
        }
        .add-text {
          font-size: 16px;
          color: #242a33;
          font-weight: 500;
          text-align: center;
        }
      }
      .input-file {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
      }
    }
    .second-part {
      display: flex;
      align-items: center;
      color: #242a33;
      font-size: 14px;
      font-weight: 400;
      .process {
        width: 260px;
        height: 10px;
        background: rgba(255, 214, 0, 0.3);
        position: relative;
        margin-right: 10px;
        .process-bar {
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          background-image: linear-gradient(270deg, #ffbf00 0%, #ffe100 100%);
        }
      }
    }
  }
  .three-part {
    width: 620px;
    height: 365px;
    margin: 30px auto 0;
    background: #f5f5f5;
    border-radius: 12px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .three-part-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      width: 349px;
      height: 349px;
      border-radius: 6px;
      .upload-img {
        height: 244px;
        width: auto;
      }
    }
    .three-part-right {
      background: #fafafc;
      height: 100%;
      width: 250px;
      padding-right: 17px;
      padding-left: 20px;
      .part-right-list {
        padding: 20px 0;
        border-bottom: 1px solid rgba(223, 223, 228, 0.6);
        .part-right-list-title {
          font-weight: 500;
          color: #242a33;
          font-size: 14px;
          margin-bottom: 8px;
        }
        .part-right-list-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #414141;
          font-size: 12px;
          .block {
            width: 26px;
            height: 14px;
            border: 1px solid #dfdfe4;
          }
        }
      }
      .part-right-list-price {
        color: #242a33;
        font-size: 14px;
        padding-top: 10px;
        .old-price {
          color: #cc210f;
          text-decoration: line-through;
        }
        .real-price {
          color: #242a33;
          font-size: 20px;
          margin-left: 8px;
        }
        .off-price {
          text-align: center;
          color: #15993d;
          margin-top: 2px;
        }
      }
    }
  }
  .again-container {
    width: 620px;
    margin: 17px auto 0;
    .again-btn {
      width: 217px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #fff;
      border: 1px solid #000;
      border-radius: 6px;
      font-size: 14px;
      margin-left: 70px;
    }
  }

  .download-text {
    font-size: 32px;
    color: #242a33;
    text-align: center;
    font-weight: 400;
    margin-top: 50px;
    span {
      color: #cc210f;
      text-decoration: line-through;
    }
  }

  .load-text {
    // width: 90%;
    font-size: 12px;
    opacity: 0.8;
    margin-top: 8px;
  }

  .real-price {
    font-size: 50px;
    color: #242a33;
    text-align: center;
    font-weight: 700;
    margin-top: 22px;
  }
  .price-off {
    font-size: 26px;
    color: #15993d;
    text-align: center;
    font-weight: 400;
    margin-top: 10px;
  }
  .upload-tips-box {
    margin: 30px auto 0;
    div {
      font-size: 14px;
      color: #242a33;
      font-weight: 400;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
.paypal-box {
  margin-top: 25px;
}
.download-photo {
  //   position: fixed;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 130px;
  //   box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  .download-photo-box {
    width: 217px;
    height: 36px;
    margin: 0 auto;
    background: #ffbf00;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 14px;
    .load-icon {
      margin-right: 10px;
    }
  }
}
</style>
